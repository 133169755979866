import {NEWS} from "../../data/newsUpdates"

export default function NewsUpdates() {
  return (
    <section className="grid grid-cols-12 py-[120px]">
        <div className="col-start-2 col-end-12 grid grid-cols-12 gap-y-10 my-10">
            <h1 className="col-start-1 col-end-13 subheader">News & Upcoming Events</h1>
            <div className="col-start-1 col-end-13 grid sm_tablet:grid-cols-3 lg_tablet:grid-cols-4 gap-x-5 gap-y-10">
                {NEWS.map(news => (
                    <div className="card">
                        <img className="card-img" src={news.img} alt="" />
                        <div className="card-info">
                            <p>{news.headline}</p>
                        </div>
                    </div>
                ))}
            </div>
        
        </div>
    </section>
  )
}
