import Format from "../Layout/format"
import AdmissionProcess from "../components/admission/AdmissionProcess"
import AdmissionSteps from "../components/admission/AdmissionSteps"
import Tuition from "../components/admission/Tuition"

export default function Admission() {
  return (
    <Format>
      <AdmissionProcess />
      <AdmissionSteps />
      <Tuition />
    </Format>
    
  )
}
