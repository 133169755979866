import { useRef } from "react";
import { Link } from "react-router-dom";

import FormInput from "../components/form/FormInput";
import Format from "../Layout/format";

export default function ParentLogin() {
  const usernameRef = useRef<HTMLInputElement>();

  const handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(usernameRef);
  };

  return (
    <Format>
      <div className="grid grid-cols-12 grid-rows-1">
        <div className="col-start-1 col-end-13 row-start-1">
          <img
            className="object-cover w-full h-screen"
            src="/img/parent_login_img.png"
            alt="parent login background"
          />
        </div>
        <div className="col-start-1 col-span-12 row-start-1 mx-auto my-12 grid grid-cols-12 sm_tablet:mx-0 sm_tablet:col-start-2 sm_tablet:col-end-7">
          <div className="col-start-2 col-end-12 grid grid-cols-12 gap-y-10 bg-slate-100 rounded-lg p-5 my-auto">
            <h3 className="col-start-1 col-span-12 subheader">Parent Portal</h3>
            <div className="col-start-1 col-span-12">
              <form className="form" onSubmit={handleSubmit}>
                <FormInput type="email" placeholder="Email Address" />
                <div>
                  <FormInput type="password" placeholder="Password" />
                  <div className="flex justify-between px-0 py-2 text-xs">
                    <div className="flex gap-x-1">
                      <input
                        type="checkbox"
                        id="remember_me"
                        className="default:bg-primary cursor-pointer"
                      />
                      <label
                        htmlFor="remember_me"
                        className="text-textColor cursor-pointer"
                      >
                        Remember Me
                      </label>
                    </div>
                    <Link className="text-primary" to={"#"}>
                      Forgot password
                    </Link>
                  </div>
                </div>
                <button className="btn-primary">Sign In</button>
                <p className="text-xs text-textColor">
                  Having trouble logging In?{" "}
                  <Link className="text-primary" to={"/contact"}>
                    contact support
                  </Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Format>
  );
}
