export const ADMISSION_STEPS = [
    {
        id: '1',
        header: 'Step 1: Application',
        description: `
            The procedure for admission into Future Ambassadors International School starts with filling out the admission form, which is obtainable via the following channels: - Online via Application Form in the Admission links below and our campus at Okpanam-Asaba Delta State. The Filled-out admission forms can be submitted through the same channels with proof of payment for the admission form.
        `
    },
    {
        id: '2',
        header: 'Step 2: Assessment',
        description: `A date will be assigned for assessment and entrance examination for students from Primary to secondary level. Subjects to be assessed are: English Language and Mathematics.`
    },
    {
        id: '3',
        header: 'Step 3: Interview',
        description: `Applicants who passed the assessment/entrance examination will be invited for an oral interview, after which all prescribed fees can be paid.

        The school reserves the right to offer and/or withdraw admission to any applicant, guided only by relevant extant laws and school policies.
        
         
         For enquiries: please call any of the following lines:
        
         +234(0)9136697539
        
        +234(0)9153303555`
    },
]