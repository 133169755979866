import { BsArrowRightCircle } from "react-icons/bs"
import { Link } from "react-router-dom"
import { NEWS } from "../../data/newsUpdates"

export default function News() {
  return (
    <section className="my-10 py-10">
      <div className="grid grid-cols-12 gap-y-10">
        <h1 className="col-start-2 col-end-12 subheader">News & Upcoming Events</h1>
        <div className="col-start-2 col-end-12 gap-y-10 grid sm_tablet:grid-cols-12">
          <div className="col-start-1 col-end-12 grid gap-x-3 gap-y-10 sm_tablet:grid-cols-3 lg_tablet:grid-cols-4">
            {NEWS.slice(0,4).map(news => (
              <div className="card">
                  <img className="card-img" src={news.img} alt="" />
                  <div className="card-info">
                    <p>{news.headline}</p>
                  </div>
              </div>
            ))}
          </div>
          <div className="col-start-1 col-end-13 m-auto sm_tablet:col-start-12 sm_tablet:col-end-13 justify-center items-center">
            <Link to={"/news"}>
              <BsArrowRightCircle size={35} />
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}
