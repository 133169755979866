import { useLocation } from "react-router-dom";
import Header from "../components/header/Header";
import Footer from "../components/footers/Footer";
import FooterContactUs from "../components/footers/FooterContactUs";

type formatProps = {
  children: React.ReactNode;
};

export default function Format({ children }: formatProps) {
  const location = useLocation();
  const pagesWithContactUsFooterLink = [
    "/contact",
    "/parent_login",
    "/teacher_login",
    "/faq",
  ];

  const showFooterContactUs = () => {
    return pagesWithContactUsFooterLink.includes(location.pathname);
  };

  return (
    <>
      <Header />
      <main>{children}</main>
      {!showFooterContactUs() && <FooterContactUs />}
      <Footer />
    </>
  );
}
