import { FAQ } from "../../data/help";

export default function Faqs() {

  return (
    <section className="grid grid-cols-12 py-[120px]">
      <div className="col-start-2 col-end-12 grid grid-cols-12">
        <h1 className="col-start-1 col-end-13 text-center subheader">Frequently Asked Questions</h1>
        <div className="col-start-1 col-end-13 my-5">
          {FAQ.map((item, i) => (
            <div className="my-5">
              <div className="gap-x-3 bg-black/25 p-3 rounded-t-2xl">
                <h2 className="text-semibold hover:cursor-pointer text-slate-100">{item.question}</h2>
              </div>
              <div className="p-3 bg-black/10 text-primary rounded-b-2xl">{item.answer}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
