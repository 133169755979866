type ImageProps = {
    imgPath: string,
    imgName: string
}

export default function Image({imgPath, imgName}: ImageProps) {
  return (
    <img className="w-full h-full object-cover" src={imgPath} alt={imgName} />
  );
}
