export default function ContactMap() {
  return (
    <div className="grid grid-cols-12">
      <iframe
        title="school location"
        className="col-start-1 col-end-13 w-full object-cover"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.2070484222722!2d6.6720283140932475!3d6.23641732816592!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8bd60f5bfed65c5b!2zNsKwMTQnMTEuMSJOIDbCsDQwJzI3LjIiRQ!5e0!3m2!1sen!2sng!4v1668093874237!5m2!1sen!2sng"
        loading="lazy"
        width="400"
        height="300"
      ></iframe>
    </div>
  );
}
