import {SCHOOL_DATA} from './SchoolData'

type schoolInfoProps = {
  selectedSchool: "playclass"| "creche" | "primary" | "secondary";
}

function selectedSchoolInfo(selectedSchool: schoolInfoProps["selectedSchool"]) {
  return SCHOOL_DATA.map(data => 
    <>
      {
        data.id === selectedSchool && (
          <div className="grid grid-cols-12 gap-y-10 sm_tablet:gap-x-5" id={selectedSchool}>
            <div className="col-start-2 col-end-12 flex flex-col gap-y-5 sm_tablet:col-end-8 sm_tablet:my-auto">
              <h3 className="subheader">{data.header}</h3>
              <p className="paragraph">{data.description}</p>
            </div>
            <div className="col-start-2 col-end-12 sm_tablet:col-start-8 sm_tablet:my-auto">
              <img
                className="object-cover w-full"
                src={data.img}
                alt="school info"
              />
            </div>
          </div>
        )
      }
    </>
  )
}

export default function SchoolInfo({selectedSchool}: schoolInfoProps) {

  return (
    <section>
      {selectedSchoolInfo(selectedSchool)}
    </section>
  );
}
