import Format from '../Layout/format'
import NewsUpdates from '../components/news/NewsUpdates'

export default function News() {
  return (
    <Format>
      <NewsUpdates />
    </Format>
  )
}
