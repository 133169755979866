import { FaChevronCircleLeft } from "react-icons/fa";
import { FaChevronCircleRight } from "react-icons/fa";
import { PLAY_CLASS, PRIMARY, SECONDARY } from "./SchoolData";

type schoolSliderProps = {
  selectedSchool: "creche" | "playclass" | "primary" | "secondary";
  setSelectedSchool: React.Dispatch<
    React.SetStateAction<"creche" | "playclass" | "primary" | "secondary">
  >;
};

export default function SchoolSlider({
  selectedSchool,
  setSelectedSchool,
}: schoolSliderProps) {
  return (
    <div className="grid grid-cols-12">
      <FaChevronCircleLeft
        className={`col-start-2 my-auto ${
          selectedSchool === "creche" ? "hidden" : ""
        } lg_tablet:hidden`}
        size={25}
        color={"#F4BA4D"}
        onClick={() => {
            if(selectedSchool === "playclass") return setSelectedSchool("creche")
            if(selectedSchool === "primary") return setSelectedSchool("playclass")
            if(selectedSchool === "secondary") return setSelectedSchool("primary")
        }}
      />
      <div className="col-start-4 col-end-10 grid grid-cols-12 lg_tablet:col-start-3 lg_tablet:col-end-11 lg_tablet:gap-x-5 lg_tablet:justify-between">
        <button
          className={`col-start-1 col-end-13 text-primary lg_tablet:col-start-1 lg_tablet:col-end-4 ${
            selectedSchool === "creche" ? "btn-primary block" : "hidden lg_tablet:block"
          }`}
          onClick={() => setSelectedSchool("creche")}
        >
          Creche
        </button>
        <button
          className={`col-start-1 col-end-13 text-primary lg_tablet:col-start-4 lg_tablet:col-end-7 ${
            selectedSchool === "playclass" ? "btn-primary block" : "hidden lg_tablet:block"
          }`}
          onClick={() => setSelectedSchool("playclass")}
        >
          Play Class
        </button>
        <button
          className={`col-start-1 col-end-13 text-primary ${
            selectedSchool === "primary" ? "btn-primary block" : "hidden lg_tablet:block"
          } lg_tablet:col-start-7 lg_tablet:col-end-10`}
          onClick={() => setSelectedSchool("primary")}
        >
          Primary
        </button>
        <button
          className={`col-start-1 col-end-13 text-primary ${
            selectedSchool === "secondary" ? "btn-primary block" : "hidden lg_tablet:block"
          } lg_tablet:col-start-10 lg_tablet:col-end-13`}
          onClick={() => setSelectedSchool("secondary")}
        >
          Secondary
        </button>
      </div>
      <FaChevronCircleRight
        className={`col-start-11 justify-self-end my-auto ${
          selectedSchool === "secondary" ? "hidden" : ""
        } lg_tablet:hidden`}
        size={25}
        color={"#F4BA4D"}
        onClick={() => {
            if(selectedSchool === "creche") return setSelectedSchool(PLAY_CLASS)
            if(selectedSchool === "playclass") return setSelectedSchool(PRIMARY)
            if(selectedSchool === "primary") return setSelectedSchool(SECONDARY)
        }}
      />
    </div>
  );
}
