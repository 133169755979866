import { FEES } from "../../data/schoolFees"

type feesProps = {
    feesClass: string
}

export default function Fees({feesClass}: feesProps) {

    const classFees = FEES.filter(fee => fee.class.includes(feesClass))
  return (
    <div className="bg-secondary/25 p-5 rounded">
      {classFees.map((fee, i) => (
        <div key={i} className="flex flex-col text-primary">
            <div className="flex justify-between">
                <span>1st Term Fees:</span>
                <span>&#8358; {fee.terms.firstTermFee}</span>
            </div>
            <div className="flex justify-between">
                <span>2nd Term Fees:</span>
                <span>&#8358; {fee.terms.secondTermFee}</span>
            </div>
            <div className="flex justify-between">
                <span>3rd Term Fees:</span>
                <span>&#8358; {fee.terms.thirdTermFee}</span>
            </div>
            <br />
            <span className="text-extrabold text-lg">Total Fees: &#8358; {fee.totalFees}</span>
        </div>
      ))}
    </div>
  )
}
