import { Link } from "react-router-dom";
import { ADMISSION_STEPS } from "../../data/admissionSteps";
import Article from "../../utilities/Article";

export default function AdmissionSteps() {
  return (
    <section className="section">
      <div
        className="section-content grid grid-cols-1 sm_tablet:grid-cols-2 gap-10"
        id="admissionSteps"
      >
        {ADMISSION_STEPS.map((step) => (
          <Article key={step.id} header={step.header}>
            {step.description}
          </Article>
        ))}
      </div>

      <Link
        to={"/application"}
        className="section-content py-2 sm_tablet:col-start-6 sm_tablet:col-end-9 btn-primary"
      >
        Apply
      </Link>
    </section>
  );
}
