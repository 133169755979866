export const FEES = [
  {
    class: "creche",
    terms: {
      firstTermFee: "150,000",
      secondTermFee: "150,000",
      thirdTermFee: "150,000",
    },
    totalFees: "450,000",
  },
  {
    class: "playclass",
    terms: {
      firstTermFee: "210,000",
      secondTermFee: "160,000",
      thirdTermFee: "180,000",
    },
    totalFees: "550,000",
  },
  {
    class: "primary",
    terms: {
      firstTermFee: "395,000",
      secondTermFee: "185,000",
      thirdTermFee: "195,000",
    },
    totalFees: "755,000",
  },
  {
    class: "jssNonBoarding",
    terms: {
      firstTermFee: "415,000",
      secondTermFee: "185,000",
      thirdTermFee: "195,000",
    },
    totalFees: "795,000",
  },
  {
    class: "jssBoarding",
    terms: {
      firstTermFee: "565,000",
      secondTermFee: "335,000",
      thirdTermFee: "345,000",
    },
    totalFees: "1,245,000",
  },
  {
    class: "sssNonBoarding",
    terms: {
      firstTermFee: "415,000",
      secondTermFee: "185,000",
      thirdTermFee: "195,000",
    },
    totalFees: "795,000",
  },
  {
    class: "sssBoarding",
    terms: {
      firstTermFee: "565,000",
      secondTermFee: "335,000",
      thirdTermFee: "345,000",
    },
    totalFees: "1,245,000",
  },
];
