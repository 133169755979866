import { ADMISSION_PROCESS } from "../../data/admissionProcess";
import ArticleAndImage from "../../utilities/ArticleAndImage";

export default function AdmissionProcess() {
  return (
    <section className="section">
      <div className="section-content" id="admissionProcess">
        <ArticleAndImage
          articleHeader={ADMISSION_PROCESS.header}
          articleImgPath="/img/admissions.gif"
          articleImgName="Admission Image"
          articleImgClass="order-last"
        >
          <p>{ADMISSION_PROCESS.description}</p>
        </ArticleAndImage>
      </div>
    </section>
  );
}
