import { useRef } from "react";
import { STATES } from "../../data/states";
import FormInput from "../form/FormInput";

export default function ApplicationForm() {
  const StateRef = useRef<HTMLSelectElement>(null);

  return (
    <div className="grid grid-cols-12 m-auto gap-y-5 align-center py-[120px]">
      <div className="col-start-1 col-span-12 subheader mb-10">
        Application Form
      </div>
      <span className="col-start-2 col-end-11 paragraph">
        Kindly fill in your details
      </span>
      <form className="col-start-2 col-end-12 flex flex-col gap-5 sm_tablet:grid sm_tablet:grid-cols-2">
        <FormInput type="text" placeholder="First Name (e.g John)" />
        <FormInput type="text" placeholder="Last Name (e.g Doe)" />
        <FormInput type="text" placeholder="Middle Name" />
        <select className="form-input">
          <option value="" disabled selected hidden>
            Gender
          </option>
          <option value="male">Male</option>
          <option value="female">Female</option>
        </select>
        <select className="form-input" id="state" ref={StateRef}>
          <option value="" disabled selected hidden>
            State of Residence
          </option>
          {Object.entries(STATES).map((state) => (
            <option value={state[0]}>{state[1]}</option>
          ))}
        </select>
        <FormInput type="text" placeholder="LGA of Residence" />
        <FormInput type="text" placeholder="Street Name & No." />
        <FormInput
          type="email"
          placeholder="Email Address (e.g name@example.com)"
        />
        <FormInput type="tel" placeholder="Phone Number (e.g 07000000000)" />
      </form>
      <button
        type="submit"
        className="btn-primary col-start-1 col-end-13 w-1/2 sm_tablet:w-1/3 mx-auto mt-10"
      >
        Submit Application
      </button>
    </div>
  );
}
