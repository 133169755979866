import Format from "../Layout/format"
import Contact from "../components/contact/Contact"

export default function ContactUs() {
  return (
    <Format>
      <Contact />
    </Format>
  )
}
