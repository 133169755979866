import { useState } from "react"

import Fees from '../components/admission/Fees'

export default function DisplayFees() {
  const [showClassFees, setShowClassFees] = useState<string>("")

  return (
    <div className="grid grid-cols-12">
      <div className="col-start-1 col-end-13 sm_tablet:col-end-8 flex flex-col gap-y-5">
        <select className="form-input" onChange={e => setShowClassFees(e.target.value)}>
          <option value="" selected hidden disabled>Select Class</option>
          <option value="sssBoarding">SS1 - SS3(Boarding)</option>
          <option value="sssNonBoarding">SS1 - SS3(Non-Boarding)</option>
          <option value="jssBoarding">JS1 - JS3(Boarding)</option>
          <option value="jssNonBoarding">JS1 - JS3(Non-Boarding)</option>
          <option value="primary">PRIMARY 1 - 5</option>
          <option value="creche">CRECHE</option>
          <option value="playclass">PLAY CLASS</option>
        </select>
      </div>
        {showClassFees && 
          <div className="col-start-1 col-end-13 sm_tablet:col-end-8">
            <Fees feesClass={showClassFees} />
          </div>
        }
    </div>
  );
}
