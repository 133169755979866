import {BiBadgeCheck} from 'react-icons/bi'
import {FiBriefcase} from 'react-icons/fi'
import {GiFamilyHouse} from 'react-icons/gi'
import {MdComputer} from 'react-icons/md'

export default function OurFacilities() {
  return (
    <>
        <div className="invisible grid grid-cols-12 col-start-1 col-span-12 lg_tablet:visible lg_tablet:col-start-2 lg_tablet:col-end-12 w-full mt-[-40px] justify-end py-2 bg-primary rounded-3xl lg_tablet:py-10">
            <div className="benefits col-start-1 col-end-4 border-r-0 sm_tablet:border-r-2 sm_tablet:border-r-slate-200 lg_tablet:items-center">
                <BiBadgeCheck className="mx-auto lg_tablet:mx-0" size={20} />
                <div className="text-center text-xs font-semibold px-2 lg_tablet:text-sm">
                    Government Approved
                </div>
            </div>
            <div className="col-start-4 col-end-7 benefits border-r-0  sm_tablet:border-r-2 sm_tablet:border-r-slate-200 lg_tablet:items-center">
            <FiBriefcase className="mx-auto lg_tablet:mx-0" size={20} />
                <div className="text-center text-xs font-semibold lg_tablet:text-sm px-2">
                    World Class Facilities
                </div>
            </div>
            <div className="col-start-7 col-end-10 benefits border-r-0 sm_tablet:border-r-2 sm_tablet:border-r-slate-200 lg_tablet:items-center lg_tablet:gap-2">
                <MdComputer className="mx-auto lg_tablet:mx-0" size={20} />
                <div className="text-center text-xs font-semibold px-2 lg_tablet:text-start lg_tablet:text-sm">
                    eLearning/<br /> Library Facilities
                </div>
            </div>
            <div className="col-start-10 col-end-13 benefits lg_tablet:items-center lg_tablet:gap-2 lg_tablet:mx-5">
                <GiFamilyHouse className="mx-auto lg_tablet:mx-0" size={20} />
                <div className="text-center text-xs font-semibold px-2 lg_tablet:text-sm lg_tablet:text-start">
                    Excellent Boarding Facilities
                </div>
            </div>
        </div>
    </>
  )
}
