export const STATES = {
    FC: "Abuja",
    AB: "Abia",
    AD: "Adamawa", 
    AK: "Akwa Ibom",
    AN: "Anambra",
    BA: "Bauchi",
    BY: "Bayelsa",
    BE: "Benue",
    BO: "Borno",
    CR: "Cross River",
    DE: "Delta",
    EB: "Ebonyi",
    ED: "Edo",
    EK: "Ekiti",
    EN: "Enugu",
    GO: "Gombe",
    IM: "Imo River",
    JI: "Jigawa",
    KD: "Kaduna",
    KN: "Kano",
    KT: "Katsina",
    KE: "Kebbi",
    KO: "Kogi",
    KW: "Kwara",
    LA: "Lagos",
    NA: "Nassarawa",
    NI: "Niger",
    OG: "Ogun",
    ON: "Ondo",
    OS: "Osun",
    OY: "Oyo",
    PL: "Plateau",
    RI: "Rivers",
    SO: "Sokoto",
    TA: "Taraba",
    YO: "Yobe",
    ZA: "Zamfara",
}



