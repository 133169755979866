import { Link } from "react-router-dom";

type RoutesProps = {
  path: string;
  routeName: string
}

export default function Routes({
  path,
  routeName,
}: RoutesProps) {
  return (
    <div className="text-tertiary hover:text-secondary cursor-pointer transition ease-out duration-500">
      <Link to={path}>{routeName}</Link>
    </div>
  );
}
