import { useState } from "react";
import { Link } from "react-router-dom";
import { HiMenuAlt3 } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";

import RouteList from "../routing/RouteList";

export default function Header() {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <header className="grid grid-cols-12 fixed w-full bg-white z-10 shadow-md">
      <div className="col-start-2 col-end-12 lg_tablet:flex items-center justify-between font-inter py-2 grid grid-cols-12">
        <Link
          to={"/"}
          className="col-start-1 col-span-6 flex gap-x-5 lg_tablet:col-start-1 lg_tablet:col-span-3"
        >
          <img className="w-16 h-16" src="/img/fais_logo.png" alt="FAIS LOGO" />
          <div className="text-start flex flex-col font-semibold text-xs text-tertiary justify-center">
            <span>Future Ambassador</span>
            <span>International School</span>
          </div>
        </Link>
        <div className="col-start-13 justify-items-end lg_tablet:hidden">
          {openMenu ? (
            <AiOutlineClose
              size={30}
              color={"#562523"}
              onClick={() => setOpenMenu(!openMenu)}
            />
          ) : (
            <HiMenuAlt3
              size={30}
              color={"#562523"}
              onClick={() => setOpenMenu(!openMenu)}
            />
          )}
        </div>
          <nav
            className={`${
              openMenu ? "" : "hidden"
            } col-start-1 col-end-13 my-8 lg_tablet:row-start-1 lg_tablet:block lg_tablet:col-start-4 lg_tablet:col-span-9`}
          >
            <RouteList />
          </nav>
      </div>
    </header>
  );
}
