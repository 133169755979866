import Routes from "./Routes";
import { FaGraduationCap } from 'react-icons/fa'
import { GrCertificate } from 'react-icons/gr'
import { AiFillQuestionCircle } from 'react-icons/ai'
import { MdQuestionAnswer } from 'react-icons/md'
import { BiNews } from 'react-icons/bi'
// import { CgGym } from 'react-icons/cg'
// import { MdDashboard } from 'react-icons/md'

export default function RouteList() {
  const icon_size = 20;
  const icon_color = '#562523'

  return (
    <div className="flex flex-col gap-5 font-normal text-sm sm_tablet:flex-row sm_tablet:justify-between">
      <div className="lg_tablet:items-center mobile-routes">
        <div className="lg_tablet:hidden">
          <GrCertificate size={icon_size} color={icon_color} />
        </div>
        <Routes path="/academics" routeName="Academics" />
      </div>
      <div className="mobile-routes">
        <div className="lg_tablet:hidden">
          <BiNews size={icon_size} color={icon_color} />
        </div>
        <Routes path="/news" routeName="News" />
      </div>
      <div className="mobile-routes">
        <div className="lg_tablet:hidden">
          <FaGraduationCap size={icon_size} color={icon_color} />
        </div>
        <Routes path="/admission" routeName="Admissions" />
      </div>
      
      {/* <div className="mobile-routes">
        <div className="lg_tablet:hidden">
          <CgGym size={icon_size} color={icon_color} />
        </div>
        <Routes path="#" routeName="Facilities" />
      </div> */}
      <div className="mobile-routes">
        <div className="lg_tablet:hidden">
          <AiFillQuestionCircle size={icon_size} color={icon_color} />
        </div>
        <Routes path="/faq" routeName="FAQs" />
      </div>
      <div className="mobile-routes">
        <div className="lg_tablet:hidden">
          <MdQuestionAnswer size={icon_size} color={icon_color} />
        </div>      
        <Routes path="/contact" routeName="Contact" />
      </div>
      {/* <div className="mobile-routes">
        <div className="lg_tablet:hidden">
          <MdDashboard size={icon_size} color={icon_color} />
        </div>
        <Routes path="#" routeName="Portal" />
      </div> */}
    </div>
  );
}
