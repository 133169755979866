import Article from "./Article";
import Image from "./Image";

type ArticleAndImageProps = {
  articleHeader: string;
  articleImgPath: string;
  articleImgName: string;
  articleImgClass?: string;
  articleClass?: string;
  children?: React.ReactNode;
};

export default function ArticleAndImage({
  articleHeader,
  articleImgPath,
  articleImgName,
  articleImgClass,
  articleClass,
  children,
}: ArticleAndImageProps) {
  return (
    <div className="grid grid-cols-1 sm_tablet:grid-cols-2 gap-y-5 sm_tablet:gap-x-10">
      <div
        className={`w-full sm_tablet:my-auto sm_tablet:row-start-1 row-start-2 lg_tablet:row-start-4 ${articleImgClass}`}
      >
        <Image imgPath={articleImgPath} imgName={articleImgName} />
      </div>
      <div
        className={`sm_tablet:row-start-1 lg_tablet:row-start-4 lg_tablet:my-auto ${articleClass}`}
      >
        <Article header={articleHeader}>
          {children}
        </Article>
      </div>
    </div>
  );
}
