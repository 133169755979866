import { Link } from "react-router-dom";
import OurFacilities from "./OurFacilities";

export default function GettingStarted() {
  return (
    <section className="grid grid-cols-12">
      <div className="col-start-1 col-end-13 w-full h-[90vh]">
        <img
          className="w-full h-full object-cover"
          src="/img/homepage_background1.jpg"
          alt="Getting Started"
        />
        <div className="max-w-[1140px] m-auto">
          <div className="absolute w-full h-full top-[12%] max-w-[550px] flex flex-col gap-y-5 px-5 sm_tablet:top-[15%]">
            <div className="bg-black/40 shadow-xl px-7 py-10 rounded-3xl">
              <div className="font-extrabold text-lg mb-5 lg_tablet:text-3xl leading-5 text-white">
                FUTURE AMBASSADORS INTERNATIONAL SCHOOL
              </div>
              <p className="font-normal text-xs sm_tablet:text-base leading-6 text-slate-200">
                Future Ambassadors International School Day/Boarding
                co-educational Play Class, Crèche, Primary, Secondary School
                located at Okpanam- Asaba, Delta State.
                <br />
                <br />
                Future Ambassadors International School offers a rich Curriculum
                and has best- in class Team of enthusiastic and committed
                Teachers, as well as staff.
                <br />
                Impacting values and preparing children to be equip for future is
                our goal.
                <br />
                <br />
                At Future Ambassador International School we are passionate about
                the overall development of a child and posses world class
                facilities...
              </p>
            </div>
            <Link to={"/admission"}>
              <div className="btn-white">Enroll Now</div>
            </Link>
          </div>
        </div>
      </div>
      <OurFacilities />
    </section>
  );
}
