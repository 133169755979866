type textAreaProp = {
    rows?: number,
    placeholder: string,
    cols?: number
}

export default function TextArea({rows, cols, placeholder}: textAreaProp) {
  return (
    <textarea className="form-input" rows={rows} cols={cols} placeholder={placeholder}></textarea>
  )
}
