import ApplicationForm from "../components/admission/ApplicationForm";
import Format from "../Layout/format";

export default function Application() {
  return (
    <Format>
        <ApplicationForm />
    </Format>
  )
}
