import Format from "../Layout/format"
import Faqs from "../components/faq/Faqs"

export default function Faq() {
  return (
    <Format>
      <Faqs />
    </Format>
  )
}
