export const NEWS = [
    {
        headline: "Annual Founders Day",
        story: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Provident delectus et at numquam accusamus in, qui eligendi fuga rem laudantium, reprehenderit minima dolore perferendis. Laudantium repellendus autem perferendis laborum tempora!",
        img: "/img/news1.png"
    },
    {
        headline: "Graduation Ceremony",
        story: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Provident delectus et at numquam accusamus in, qui eligendi fuga rem laudantium, reprehenderit minima dolore perferendis. Laudantium repellendus autem perferendis laborum tempora!",
        img: "/img/news2.png"
    },
    {
        headline: "Graduation Ceremony",
        story: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Provident delectus et at numquam accusamus in, qui eligendi fuga rem laudantium, reprehenderit minima dolore perferendis. Laudantium repellendus autem perferendis laborum tempora!",
        img: "/img/news3.png"
    },
    {
        headline: "Inter-House Sport Competition",
        story: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Provident delectus et at numquam accusamus in, qui eligendi fuga rem laudantium, reprehenderit minima dolore perferendis. Laudantium repellendus autem perferendis laborum tempora!",
        img: "/img/news4.png"
    },

]