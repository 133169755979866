import {Routes, Route} from 'react-router-dom'

// Components
import HomePage from './pages/Home'
import TeacherLoginPage from './pages/TeacherLogin'
import ParentLoginPage from './pages/ParentLogin'
import Admission from './pages/Admission'
import Academics from './pages/Academics'
import Faq from './pages/Faq'
import Contact from './pages/ContactUs'
import News from './pages/News'
import Facilities from './pages/Facilities'
import Application from './pages/Application'

function App() {
  return (
    <Routes>
      <Route path='/' element={<HomePage />} />
      <Route path='/parent_login' element={<ParentLoginPage />} />
      <Route path='/teacher_login' element={<TeacherLoginPage />} />
      <Route path='/academics' element={<Academics />} />
      <Route path="/admission" element={<Admission />} />
      <Route path='/faq' element={<Faq />} />
      <Route path='/contact' element={<Contact />} />
      <Route path='/news' element={<News />} />
      <Route path='/facility' element={<Facilities />} />
      <Route path='/application' element={<Application />} />
    </Routes>
  );
}

export default App;
