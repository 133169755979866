import Format from '../Layout/format'

// Components
import GettingStarted from '../components/home/GettingStarted'
import WelcomeAddress from '../components/home/WelcomeAddress'
import Schools from '../components/home/Schools'
import News from '../components/home/News'
import Testimonies from '../components/home/Testimonies'

export default function HomePage() {
  return (
    <Format>
      <div className='lg_tablet:py-[100px]'>
        <GettingStarted />
        <WelcomeAddress />
        <Schools />
        <Testimonies />
        <News />
      </div>
      </Format>
  )
}
