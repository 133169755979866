type ArticleProps = {
  header: string,
  children?: React.ReactNode
}

export default function Article({header, children}: ArticleProps) {
  return (
    <div className="flex flex-col gap-x-5 gap-y-10">
      <h1 className="subheader">{header}</h1>
      <p className="paragraph">{children}</p>
    </div>
  );
}
