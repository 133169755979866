import { ImWhatsapp, ImFacebook, ImInstagram, ImTwitter } from "react-icons/im";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer>
        <div className="bg-gray-800 grid grid-cols-12 py-5 px-3 gap-y-5 lg_tablet:py-10">
          <Link
            to={"/"}
            className="font-semibold col-start-2 col-end-13 leading-4 text-xl text-slate-200 lg_tablet:text-2xl sm_tablet:col-start-2 sm_tablet:col-end-7"
          >
            <div className="flex gap-x-5 items-center">
              <img
                className="w-26 h-20"
                src="/img/fais_logo_256x256.png"
                alt="FAIS LOGO"
              />
              <div className="flex flex-col text-sm sm_tablet:text-lg">
                <span>Future Ambassador</span>
                <span>International School</span>
                <span>Asaba</span>
              </div>
            </div>
          </Link>

          <div className="col-start-2 col-end-12 flex justify-between gap-x-5 sm_tablet:col-start-8 sm_tablet:col-end-12">
            <div className="flex justify-between gap-x-8 sm_tablet:gap-x-12">
              {/* Info */}
              <div>
                <h3 className="footer-list-header">Info</h3>
                <div className="footer-list-group">
                  <Link to={"/admission"} className="footer-list-item text-sm">
                    Admissions
                  </Link>
                  <Link to={"/academics"} className="footer-list-item text-sm">
                    Academics
                  </Link>
                  <Link to={"/faq"} className="footer-list-item text-sm">
                    Help
                  </Link>
                  <Link to={"/facility"} className="footer-list-item text-sm">
                    Facilities
                  </Link>
                  <Link to={"/news"} className="footer-list-item text-sm">
                    News
                  </Link>
                </div>
              </div>

              <div className="col-start-10 col-end-13">
                {/* Portal links */}
                <div className="col-start-7 col-end-10">
                  <h3 className="footer-list-header">Portal</h3>
                  <div className="footer-list-group">
                    <Link to={"/parent_login"} className="footer-list-item">
                      Parent Login
                    </Link>
                    <Link to={"/teacher_login"} className="footer-list-item">
                      Teacher Login
                    </Link>
                  </div>
                </div>
              </div>

              {/* Social Links */}
              <div>
                <h3 className="footer-list-header">Social</h3>
                <div className="flex my-2 text-xs gap-x-5 sm-tablet:gap-x-5 lg_tablet:gap-x-7">
                  <div>
                    <a
                      href="https://facebook.com/profile.php?id=100086002880998&mibextid=ZbWKwL/"
                      target="_blank"
                      className="social-icons"
                      rel="noopener noreferrer"
                    >
                      <ImFacebook />
                    </a>
                  </div>
                  <div>
                    <Link to={"#"} className="social-icons">
                      <ImWhatsapp />
                    </Link>
                  </div>
                  <div>
                    <Link to={"#"} className="social-icons">
                      <ImInstagram />
                    </Link>
                  </div>
                  <div>
                    <Link to={"#"} className="social-icons">
                      <ImTwitter />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-start-2 col-end-12 text-xs text-center text-slate-500 align-baseline">
              &copy; Copyright Future Ambassadors School 2023
            </div>
        </div>
    </footer>
  );
}
