import { Link } from "react-router-dom";

export default function FooterContactUs() {
  return (
    <div className="grid grid-cols-12">
      <div className="relative col-start-1 col-end-13  w-full h-[40vh]">
        <img
          src="/img/school_compound.jpg"
          className="object-cover h-full w-full"
          alt="Footer Contact"
        />
        <div className="overlay"></div>
        <div className="col-start-2 col-end-12 absolute top-[40%] m-auto grid grid-cols-12 gap-5 w-full sm_tablet:flex-row">
          <div className="col-start-3 col-end-11 font-bold text-lg sm_tablet:text-2xl text-center text-white sm_tablet:col-start-2 sm_tablet:col-end-8">
            Want to find out more about us?
          </div>
          <Link
            to={"/contact"}
            className="col-start-3 col-end-11 text-primary py-3 bg-white text-center rounded-3xl font-semibold text-xl hover:opacity-90 sm_tablet:col-start-8 sm_tablet:col-end-11"
          >
            Contact Us
          </Link>
        </div>
      </div>
    </div>
  );
}
