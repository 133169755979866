export const PLAY_CLASS = 'playclass'
export const CRECHE = 'creche'
export const PRIMARY = 'primary'
export const SECONDARY = 'secondary'

export const SCHOOL_DATA = [
    {
      id: 'creche',
      header: 'Creche',
      img: "/img/creche_academics.png",
      description: `
      The Creche provides supervision and care of infants and young children during the daytime, particularly so that their parents can hold jobs without having to worry about the safety and security of their children. At crèche, it is ensured that your child is well-fed, feeling comfortable and is in a cheerful frame of mind. 
      `
    },
    {
      id: 'playclass',
      header: 'Play Class',
      img: "/img/play_class_home1.png",
      description: `
      At Play class, we offer early childhood education to children before they begin compulsory education at primary school. We create activities for children to learn by their own observation and imagination to increase their language, writing and reading skills. We provide a safe and secure environment for your children.
      `
    },
    {
      id: 'primary',
      header: 'Primary School',
      img: "/img/primary_academics.png",
      description: `
      At Primary school level we give our students a strong educational foundation with 8 core/compulsory subjects, preparing them for higher levels of learning. Imbibing in them good morals and characters to form respectable individuals in the society and the world at large.
      `
    },
    {
      id: 'secondary',
      header: 'Secondary School',
      img: "/img/secondary_academics.png",
      description: `
      In Junior Secondary school (JSS) Our curriculum has 12 Core Subjects for our pupils in Junior Secondary School and we also have 3 Elective Options available. Our Senior Secondary School curriculum is split over 3 years (Years 10, 11 and 12). Here, our faculty teach 12 Core Subjects.


      `
    }
  ]