import { IoChevronBackCircleSharp, IoChevronForwardCircleSharp } from "react-icons/io5";
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im"
import { TESTIMONIES } from "../../data/testimonies";

const testimonies = () => {
  return TESTIMONIES.map(testimony => (
    <div className="col-start-2 row-start-1 col-end-12 lg_tablet:col-start-4 lg_tablet:col-end-10 py-10">
          <div className="py-2 justify-center text-center font-bold text-2xl subheader">What Parents Are Saying?</div>
          <div className="mt-10">
            <div className="mx-auto text-center text-sm px-10">{testimony.testimony}</div>
            <br /><br />
            <h1 className="mx-auto text-center text-md font-semibold">{testimony.parent}</h1>
            <p className="mx-auto text-center text-xs">{testimony.parent_info}</p>
          </div>
        </div>
  ))
}

export default function Testimonies() {
  return (
    <section>
      <div className="grid grid-cols-12 grid-rows-1 text-black/60">
        <div className="hidden">
            <ImQuotesLeft color="white" size={200} />
        </div>
        <div className="col-start-2 row-start-1 my-auto">
          <IoChevronBackCircleSharp color="white" size={35} />
        </div>
        <div className="col-start-11 row-start-1 my-auto justify-self-end">
          <IoChevronForwardCircleSharp color="white" size={35} />
        </div>
        {testimonies()}
        <div className="hidden">
          <ImQuotesRight color="white" size={200} />
        </div>
      </div>
    </section>
  );
}
