export default function Schools() {
  return (
    <section className="my-10 py-10">
      <div className="grid grid-cols-12 gap-y-10">
        <h1 className="col-start-2 col-end-12 subheader">Schools</h1>
        <div className="grid grid-cols-12 sm_tablet:grid-row-1 col-start-2 col-end-12 gap-y-10 sm_tablet:gap-x-5">
          <div className="card col-start-1 col-end-13 row-start-2  sm_tablet:col-start-1 sm_tablet:col-end-4 sm_tablet:row-start-1">
              <img className="card-img" src={"/img/creche_home.png"} alt="" />
              <div className="card-info">Creche</div>
          </div>
          <div className="card col-start-1 col-end-13 sm_tablet:col-start-4 sm_tablet:col-end-7">
              <img className="card-img row-start-1" src={"/img/play_class_home1.png"} alt="" />
              <div className="card-info">Play Class</div>
          </div>
          <div className="card col-start-1 col-end-13 sm_tablet:col-start-7 sm_tablet:col-end-10 row-start-3 sm_tablet:row-start-1">
              <img className="card-img" src={"/img/primary_home.png"} alt="" />
              <div className="card-info">Primary</div>
          </div>
          <div className="card col-start-1 col-end-13 sm_tablet:col-start-10 sm_tablet:col-end-13 row-start-4 sm_tablet:row-start-1">
              <img className="card-img" src={"/img/secondary_home.png"} alt="" />
              <div className="card-info">Secondary</div>
          </div>
        </div>
      </div>
    </section>
  )
}
