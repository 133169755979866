type FormInputProps = {
    placeholder: string,
    type: string
}

export default function FormInput({placeholder, type}: FormInputProps) {
  return (
    <div>
      <input className="form-input" type={type} placeholder={placeholder} />
    </div>
  )
}
