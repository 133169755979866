export const FAQ = [
    {
        question: 'What are the range of classes in the school?',
        answer: " -> Our classes range from the crèche, play school, Primary 1 - 6,  Junior and Senior secondary Schools."
    },
    {
        question: 'Does the school provide lunch for the students?',
        answer: " -> Yes, we provide lunch to the students as this is included in the tuition fee."
    },
    {
        question: 'Are all classes taught in English Language?',
        answer: " -> For unified understanding of the lessons taught, classes are taught in English asides from language subjects like French and Chinese"
    },
    {
        question: 'FAIS register and prepare students for external examinations like WAEC, NECO, IGSCE, SAT, TOEFL?',
        answer: " -> YES, We pride ourselves on ensuring that our students our prepared for the next phase of their educational life."
    },
    {
        question: 'What curriculum does your school teach?',
        answer: " -> We teach the Nigerian and British curriculum."
    },
    {
        question: 'Does your school go on excursions?',
        answer: " -> Yes. The excursions we often embark on have both social and educational values."
    },
    {
        question: 'How many campuses does the school have and where are their locations?',
        answer: " -> Presently we have one campus, located at okpanam- Asaba, Delta state."
    },
    {
        question: 'How do parents get information from the school?',
        answer: " -> Through newsletters, text messages, e-mails, from the school's website and dedicated online portal."
    },

]