import ArticleAndImage from "../../utilities/ArticleAndImage";
import DisplayFees from "../../utilities/DisplayFees";
import { TUITION } from "../../data/tuition";

export default function Tuition() {
  return (
    <section className="section" >
      <div className="section-content" id="tuition">
        <ArticleAndImage
          articleHeader={TUITION.header}
          articleImgPath="/img/tuition_fee.png"
          articleImgName="Tuition Image"
          articleImgClass="order-last"
        > 
          <p>{TUITION.description}</p><br />
          <DisplayFees />
        </ArticleAndImage>
      </div>
    </section>
  );
}
