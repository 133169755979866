import ContactForm from "./ContactForm"
import ContactMap from "./ContactMap"
import {GoLocation} from "react-icons/go"
import {AiOutlinePhone} from "react-icons/ai"

export default function Contact() {
  return (
    <section className="grid grid-cols-12 py-[120px]">
        <div className="col-start-2 col-end-12 grid grid-cols-12 gap-3">
            <div className="col-start-1 col-span-12 sm_tablet:col-start-1 sm_tablet:col-end-6">
                <h1 className="subheader sm_tablet:text-start">Contact Us</h1>
                <p className="paragraph sm_tablet:text-start mt-5">{`For more information, comments or complaint, Kindly fill the form below and we will get back to you`}</p>
            </div>
            <div className="col-start-1 col-span-12 grid grid-cols-12 my-5">
                <div className="col-start-1 col-span-12 flex flex-col gap-y-3 sm_tablet:col-start-1 sm_tablet:col-end-6">
                    <ContactForm />
                </div>
                <div className="col-start-1 col-end-13 my-5 sm_tablet:col-start-8 sm_tablet:col-end-13 sm_tablet:my-0">
                    <div className="col-start-1 col-span-12 flex flex-col gap-3">
                        <div className="flex gap-x-5 items-center">
                            <AiOutlinePhone size={20} color={"#562523"} />
                            <span className="text-xs text-textColor align-baseline">+234 913 6697 539, +234 915 3303 555</span>
                        </div>
                        <div className="flex gap-x-5 items-center">
                            <GoLocation size={20} color={"#562523"} />
                            <span className="text-xs text-textColor">Plot 5/11 Future Ambassadors Int'l Schools Drive Okpanam-Asaba, Delta State, Nigeria.</span>
                        </div>
                    </div>
                    <div className="col-start-1 col-end-13 my-3">
                        <ContactMap />
                    </div>
                </div>
            </div>

        </div>
    </section>
  )
}
