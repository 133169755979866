import ArticleAndImage from "../../utilities/ArticleAndImage";

export default function WelcomeAddress() {
  return (
    <section className="section">
      <div className="section-content">
        <ArticleAndImage
          articleImgPath="/img/welcome_person1.png"
          articleImgName="Principal Image"
          articleHeader="Why you should join us"
        >
        <p>
          In our unique and educational experience, our commitment to supporting and nurturing all students to be their very BEST, is what we do BEST! Our four underpinning values of Excellence, Creativity, Innovation, and Service ensure that in all we do, your child/ward will be a collaborator, open-minded, curious, linguistically adept, and equipped with life skills necessary for survival in the real world.
          <br /><br />
          Also, our day school, as well as boarding, provide great flexibility for families and every aspect of school provision is of the highest quality. As a smaller home within the school, each hostel offers an enriching and supportive environment where students are to grow as individuals and as members of their community. 
          <br /><br />
          Our goal is to set a record of excellence for your child education which has propelled many esteemed families to join our educational institution. We will be glad to receive you on a short visit on our campus in order to convince you of the legacy we have envision for your child and we will continue to build on.
          <br /><br />
          <i>EGBO SAMUEL Director, Office of MD/CEO</i>
        </p>
        </ArticleAndImage>
      </div>
    </section>
  );
}
