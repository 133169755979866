import FormInput from "../form/FormInput"
import TextArea from "../form/TextArea"

export default function ContactForm() {
  return (
    <form className="flex flex-col gap-5">
      <FormInput type="text" placeholder="Name" />
      <FormInput type="email" placeholder="Email Address" />
      <FormInput type="text" placeholder="Subject" />
      <TextArea rows={5} placeholder="Message" />
      <button type="submit" className="btn-primary">Submit</button>
    </form>
  )
}
