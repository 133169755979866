import Format from '../Layout/format'
import { useState } from 'react'

// Components
import SchoolInfo from '../components/academics/SchoolInfo'
import SchoolSlider from '../components/academics/SchoolSlider'

export default function Academics() {
  const [selectedSchool, setSelectedSchool] = useState<'creche' | 'playclass' | 'primary' | 'secondary'>('creche')
  return (
    <div>
      <Format>
        <div className="grid grid-cols-12 py-10">
            <div className="col-start-2 col-end-12 grid grid-cols-12 py-10 my-12 gap-y-10 sm_tablet:gap-x-5">
              <div className="col-start-1 col-end-13 flex flex-col gap-y-5 sm_tablet:col-end-8 sm_tablet:my-auto">
                  <h3 className="subheader">Academics</h3>
                  <p className='paragraph'>
                    At Future Ambassador International School we take great pride in our ability to help our pupils reach their full potential and prepare them for life ahead. With a rich curricula, our pupils can gain valuable educational certification for use domestically and around the world. We firmly believe in providing our pupils with a great learning experience that stays with them all through their lifetime. 
                    <br /><br />
                    Academic life at Future Ambassadors International School begins as early as Creche for children aged 3months – 11months, play class for children aged  2years – 3years,  Primary School for pupils aged 6years and above,  Junior Secondary School (JSS) for pupils aged 12 – above. 
                    <br />
                    Our curriculum has 12 Core Subjects for our pupils in Junior Secondary School and we also have 3 Elective Options available. By giving our pupils a strong educational foundation right from their early years in play class up till Junior Secondary School, they are fully prepared for their exams and Senior Secondary School.
                    <br /><br />
                    At the age of 15 our pupils move to FAIS Senior Secondary School (SSS) until they graduate at 17. Our Senior Secondary School curriculum is split over 3 years (Years 10, 11 and 12). Here, our faculty teach 12 Core Subjects and build on the strong educational foundation our pupils’ already have.
                    
                  </p>
              </div>
              <div className="col-start-1 col-end-13 sm_tablet:col-start-8 sm_tablet:my-auto">
                  <img className="object-cover w-full" src="/img/academics.gif" alt="Academics" />
              </div>
            </div>
            <div className="col-start-1 col-end-13 py-10">
              <SchoolSlider selectedSchool={selectedSchool} setSelectedSchool={setSelectedSchool} />
            </div>
            <div className='col-start-1 col-end-13 py-10'>
              <SchoolInfo selectedSchool={selectedSchool} />
            </div>
        </div>
    </Format>
    </div>
  )
}
